import { I18n } from '@aws-amplify/core';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { Auth } from 'aws-amplify';
import { AxiosResponse } from 'axios';
import { routerActions } from 'connected-react-router';
import React, { lazy, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import './App.scss';
import { useAppDispatch, useAppSelector } from './hooks';
import { COGNITO_TRANSLATIONS } from './i18';
import { Shell } from './pages/shell';
import { isLoading, stopLoading } from './slice/loadingSlice';
import { CognitoPayload, signIn, signOutAsync } from './slice/userSlice';
import { format } from 'date-fns';
import Lesson from './pages/lesson';
import Fee from './pages/fee';
import Learner from './pages/learner';
import Spinner from './components/Spinner';
import { ErrorBoundaryWrapper, ErrorPage } from './pages/error';
import { ERROR_MESSAGE } from './constant/message';
import { ERROR_CODE } from './constant/errorCode';
import RouteWrapper from './components/RouteWrapper/RouteWrapper';

const Top = lazy(() => import('./pages/top/top'));
const Signin = lazy(() => import('./pages/signin/signin'));
const Notices = lazy(() => import('./pages/notices/notices'));
const Feedback = lazy(() => import('./pages/feedback/feedback'));
const LessonNotes = lazy(() => import('./pages/lesson_notes/lesson_notes'));
const Contact = lazy(() => import('./pages/contact/index'));
const Lessons = lazy(() => import('./pages/lessons/index'));
const Fees = lazy(() => import('./pages/fees/index'));

I18n.putVocabulariesForLanguage('ja', COGNITO_TRANSLATIONS);
const PrivateRoute = ({ render, userState, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={
        userState.authState === AuthState.SignedIn && userState.user
          ? render
          : () => <Redirect to="/signin" />
      }
    />
  );
};
export const Loading = () => <Spinner />;

const extractCognitoPayload = (authenticatedUser: any): CognitoPayload => {
  try {
    return {
      'cognito:username': authenticatedUser.signInUserSession.idToken.payload['cognito:username'],
      'cognito:companyId': authenticatedUser.attributes['custom:companyId'],
      email: authenticatedUser.signInUserSession.idToken.payload.email,
      exp: authenticatedUser.signInUserSession.idToken.payload.exp,
    };
  } catch (error) {
    throw error;
  }
};
const App = () => {
  const userState = useAppSelector((state) => state.user);
  const isloading = useAppSelector(isLoading);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const init = async () => {
      const authenticatedUser = await Auth.currentAuthenticatedUser().catch((e) =>
        console.error(e),
      );

      if (authenticatedUser) {
        const expayload = extractCognitoPayload(authenticatedUser);
        dispatch(
          signIn({
            authState: AuthState.SignedIn,
            user: expayload,
          }),
        );
        dispatch(stopLoading());
      } else {
        await dispatch(signOutAsync());
        dispatch(stopLoading());
        dispatch(routerActions.push('/signin'));
      }
    };
    init();
    return onAuthUIStateChange((nextAuthState, authenticatedUser) => {
      if (nextAuthState === AuthState.SignedIn && authenticatedUser) {
        dispatch(
          signIn({
            authState: AuthState.SignedIn,
            user: extractCognitoPayload(authenticatedUser),
          }),
        );
      }
    });
  }, [dispatch]);
  return isloading ? (
    <ErrorBoundaryWrapper>
      <Loading />
    </ErrorBoundaryWrapper>
  ) : (
    <ErrorBoundaryWrapper>
      <RouteWrapper>
      <React.Suspense fallback={<Loading />}>
        <Shell>
          <Switch>
            <Route
              exact
              path="/"
              render={() => {
                return userState.authState === AuthState.SignedIn && userState.user ? (
                  <Redirect to="/top" />
                ) : (
                  <Redirect to="/signin" />
                );
              }}
            />
            <Route
              exact
              path="/signin"
              render={() => {
                return userState.authState === AuthState.SignedIn && userState.user ? (
                  <Redirect to="/top" />
                ) : (
                  <Signin />
                );
              }}
            />
            <PrivateRoute exact path="/top" render={() => <Top />} userState={userState} />
            <PrivateRoute exact path="/notices" render={() => <Notices />} userState={userState} />
            <PrivateRoute exact path="/lessons" render={() => <Lessons />} userState={userState} />
            <PrivateRoute
              exact
              path="/lesson/:companyId/:lessonId"
              render={() => <Lesson />}
              userState={userState}
            />
            <PrivateRoute exact path="/contact" render={() => <Contact />} userState={userState} />
            <PrivateRoute
              exact
              path="/feedback"
              render={() => <Feedback />}
              userState={userState}
            />
            <PrivateRoute
              exact
              path="/lesson_notes"
              render={() => <LessonNotes />}
              userState={userState}
            />
            <PrivateRoute exact path="/fees" render={() => <Fees />} userState={userState} />
            <PrivateRoute
              exact
              path="/fees/:teacherId/:yearMonth"
              render={() => <Fee />}
              userState={userState}
            />
            <PrivateRoute
              exact
              path="/lesson/:companyId/:lessonId/learners/:learnerId"
              render={() => <Learner />}
              userState={userState}
            />
            <Route
              exact
              path="*"
              render={() => {
                return (
                  <ErrorPage
                    errorCode={ERROR_CODE.HTTP_404}
                    error={new Error(ERROR_MESSAGE.HTTP_404.ja + ` url:${document.location.href}`)}
                    message={ERROR_MESSAGE.HTTP_404.ja}
                  />
                );
              }}
            />
          </Switch>
        </Shell>
      </React.Suspense>
      </RouteWrapper>
    </ErrorBoundaryWrapper>
  );
};
export default App;

export const exchangeTimeZone = (datedime: Date) => {
  let localDatetime = datedime.toLocaleString();
  // 出力用にフォーマット
  let fromattedTime = format(Date.parse(localDatetime), 'yyyy/MM/dd HH:mm:ss');
  return fromattedTime;
};
